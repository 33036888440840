import revive_payload_client_gQlF6l0SOf from "D:/word/qctsw-vue/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.8.0_@types+node@20.10.3_@unocss+reset@0.60.3_eslint@8.55.0_f_4qqb5mhawrn26h6ep63dhqv3te/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KX2dIT3dDI from "D:/word/qctsw-vue/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.8.0_@types+node@20.10.3_@unocss+reset@0.60.3_eslint@8.55.0_f_4qqb5mhawrn26h6ep63dhqv3te/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VBee7E1mhY from "D:/word/qctsw-vue/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.8.0_@types+node@20.10.3_@unocss+reset@0.60.3_eslint@8.55.0_f_4qqb5mhawrn26h6ep63dhqv3te/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_7PJYOjI4HZ from "D:/word/qctsw-vue/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.8.0_@types+node@20.10.3_@unocss+reset@0.60.3_eslint@8.55.0_f_4qqb5mhawrn26h6ep63dhqv3te/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_HF79utlHnQ from "D:/word/qctsw-vue/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.8.0_@types+node@20.10.3_@unocss+reset@0.60.3_eslint@8.55.0_f_4qqb5mhawrn26h6ep63dhqv3te/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_4iFroYF74r from "D:/word/qctsw-vue/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.3.2_vue@3.4.20/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/word/qctsw-vue/packages/app/.nuxt/components.plugin.mjs";
import prefetch_client_BfTKx8846k from "D:/word/qctsw-vue/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.8.0_@types+node@20.10.3_@unocss+reset@0.60.3_eslint@8.55.0_f_4qqb5mhawrn26h6ep63dhqv3te/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_STsxLw8EHl from "D:/word/qctsw-vue/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "D:/word/qctsw-vue/packages/app/.nuxt/unocss.mjs";
import plugin_jsLtXCcd56 from "D:/word/qctsw-vue/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_fbE1QcW9fK from "D:/word/qctsw-vue/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.8.0_@types+node@20.10.3_@unocss+reset@0.60.3_eslint@8.55.0_f_4qqb5mhawrn26h6ep63dhqv3te/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_ZGaSyn0RZJ from "D:/word/qctsw-vue/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _1_naive_ui_gDBuQl6Ccm from "D:/word/qctsw-vue/packages/app/src/plugins/1.naive-ui.ts";
import abort_4GEjbTgkOb from "D:/word/qctsw-vue/packages/app/src/plugins/abort.ts";
import error_t9vrANEheK from "D:/word/qctsw-vue/packages/app/src/plugins/error.ts";
import images_ZPfaD2QGDe from "D:/word/qctsw-vue/packages/app/src/plugins/images.ts";
import runtime_hooks_ryc2AVBBPO from "D:/word/qctsw-vue/packages/app/src/plugins/runtime-hooks.ts";
export default [
  revive_payload_client_gQlF6l0SOf,
  unhead_KX2dIT3dDI,
  router_VBee7E1mhY,
  payload_client_7PJYOjI4HZ,
  check_outdated_build_client_HF79utlHnQ,
  plugin_vue3_4iFroYF74r,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BfTKx8846k,
  plugin_client_STsxLw8EHl,
  unocss_MzCDxu9LMj,
  plugin_jsLtXCcd56,
  chunk_reload_client_fbE1QcW9fK,
  plugin_ZGaSyn0RZJ,
  _1_naive_ui_gDBuQl6Ccm,
  abort_4GEjbTgkOb,
  error_t9vrANEheK,
  images_ZPfaD2QGDe,
  runtime_hooks_ryc2AVBBPO
]