export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover"},{"name":"baidu-site-verification","content":"3zVgcSwTyl"},{"name":"description","content":"汽车投诉网是一家专业的汽车质量投诉平台，为广大车主提供权威的汽车质量投诉服务。我们提供最新的汽车新闻、评测和视频内容，此外，我们关注汽车投诉排行、汽车质量排行和汽车故障排行榜等信息，致力于推动汽车行业的进步。"},{"name":"keywords","content":"汽车投诉网, 汽车质量投诉,汽车质量投诉平台,汽车质量问题解决, 专业, 汽车, 质量, 投诉服务, 新闻, 评测, 视频, 汽车之家, 车友, 车友圈, 社区, 车质网, 汽车救援, 车主投诉, 汽车投诉排行, 汽车质量排行, 汽车故障排行榜, 315投诉网, 汽车网上投诉, 汽车召回"},{"http-equiv":"x-ua-compatible","content":"IE=edge"},{"http-equiv":"cache-control","content":"no-cache"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"dns-prefetch","href":"https://qcts.cdn.bcebos.com/"},{"rel":"dns-prefetch","href":"https://qcts.gz.bcebos.com/"},{"rel":"dns-prefetch","href":"https://hm.baidu.com/"}],"style":[],"script":[{"children":"\n                var _hmt = _hmt || [];\n                (function() {var hm = document.createElement(\"script\"); hm.src = \"https://hm.baidu.com/hm.js?bd8e392dd03bfce8b43fe60dc4247c5a\"; var s = document.getElementsByTagName(`script`)[0]; s.parentNode.insertBefore(hm, s);})();\n              "}],"noscript":[],"title":"汽车投诉网-提供权威的汽车质量投诉服务"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false